import { JOB_SERVER } from '@/servers'

const getBase64ByUrl = async (url) => {
    
    try {
        const blob = await getBlobByUrl(url)
        
        // Преобразование Blob-объекта в base64-строку
        const reader = new FileReader()
        
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    } catch (e) {
        console.error(e)
        return null
    }
}

const getBlobByUrl = async (url) => {
    
    try {
        // Проверка URL-адреса
        if (!url)
            throw new Error('Не указан URL-адрес изображения')
    
        // Загрузка изображения
        const response = await fetch(url);
        
        if (!response.ok)
            throw new Error('Ошибка загрузки изображения')
    
        // Извлечение Blob-объекта из ответа
        return await response.blob()
        
    } catch (e) {
        console.error(e)
        return null
    }
}

const normalizeUrl = (url) => {
    let src = url.replaceAll('../../', '/').replaceAll('../', '/')
    if (src.startsWith('http')) {
        return src;
    }
    src = `/${src}`.replaceAll('//', '/')
    return `${JOB_SERVER}${src}`
}

const getURLsFromString = (str) => {

    const regex = /"(\.\.)?(\/)?(\.\.)?\/?upload\/.*?"/gm;

    let m;
    const links = []

    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex)
            regex.lastIndex++
        
        const link = m[0]?.replaceAll('\\"', '')?.replaceAll('"', '')
        links.push(link)
    }

    return links
}

const replaceURLsWithBase64 = async (str) => {

    const links = getURLsFromString(str)
    const normalizedUrls = links.map((link) => normalizeUrl(link))
    const base64OfUrls = []

    for (const url of normalizedUrls) {
        const base64 = await getBase64ByUrl(url)
        base64OfUrls.push(base64)
    }

    let result = str
    for (let index = 0; index < links.length; index++)
        result = result.replace(links[index], base64OfUrls[index])

    return result
}

export {
    replaceURLsWithBase64,
    getURLsFromString,
    normalizeUrl,
    getBase64ByUrl,
    getBlobByUrl
}