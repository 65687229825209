import JSZip from 'jszip'
import unzip from 'unzip-js'
import { createFile } from './File'

const concatenateUint8Arrays = (array1, array2) => {
    // Создаем новый Uint8Array, длина которого равна сумме длин двух массивов
    const concatenatedArray = new Uint8Array(array1.length + array2.length)

    // Копируем данные из первого массива
    concatenatedArray.set(array1, 0)

    // Копируем данные из второго массива, начиная с позиции array1.length
    concatenatedArray.set(array2, array1.length)

    return concatenatedArray
}

// filesNamesToExtract - [{ path: 'path/to/file.ext', mimeType: 'some/type' }]
const extractFiles = async (compressedFile, filesNamesToExtract) => {

    if (!filesNamesToExtract?.length || typeof filesNamesToExtract[0] !== 'object') throw new Error('Second argument must be a type of Array<Object>')
    
    const extractedFiles = []

    let passedEntries = 0

    return new Promise (resolve => {
        
        unzip(compressedFile, (err, zipFile) => {
        
            if (err) {
                console.error(err)
                return resolve({ success: false, error: 'Невозможно прочитать файл неизвестной структуры' })
            }

            zipFile.readEntries((err, entries) => {

                if (err) {
                    console.error(err)
                    return resolve({ success: false, error: `Ошибка чтения файла #A1` })
                }

                entries.forEach(entry => {

                    if (filesNamesToExtract.find((f) => f.path !== entry.name)) return

                    zipFile.readEntryData(entry, false, (err, readStream) => {
                        
                        if (err) {
                            console.error(err)
                            return resolve({ success: false, error: `Ошибка чтения файла #A2` })
                        }

                        let entryData = null

                        readStream.on('data', chunk => {
                            if (entryData === null)
                                entryData = chunk
                            else
                                entryData = concatenateUint8Arrays(entryData, chunk)
                        })
                        readStream.on('error', err => {
                            console.error(err)
                            return resolve({ success: false, error: `Ошибка чтения файла #A3` })
                        })
                        readStream.on('end', async () => {
                            
                            readStream?.close?.()

                            const mimeType = filesNamesToExtract.find((f) => f.path === entry.name)?.mimeType || 'text/plain'
                            extractedFiles.push(
                                createFile(
                                                {
                                                    content: entryData,
                                                    type: mimeType,
                                                    name: entry.name.split('/').pop()
                                                }
                                            )
                            )

                            if (passedEntries === entries.length || extractedFiles.length === filesNamesToExtract.length)
                                resolve({ success: true, data: extractedFiles })
                        })
                    })
                })
            })
        })
    })
}

// newFiles - [{ path: 'path/to/file.ext', content: Blob }]
const modifyZip = async (zipFile, newFiles) => {
    
    const jszip = new JSZip()

    // Шаг 1: Чтение архива
    const zip = await jszip.loadAsync(zipFile)

    // Шаг 2: Добавление новых файлов
    for (const { path, content } of newFiles) {
        zip.file(path, content)
    }

    // Шаг 3: Генерация нового архива
    const updatedZip = await zip.generateAsync({ type: 'blob' });

    return updatedZip
}

export {
    modifyZip,
    extractFiles
}