export const saveDataAsFile = ({
    data = '',
    type = 'text/plain',
    name = 'unnamed.txt'
} = {}) => {

    const blob = data instanceof Blob ? data : new Blob([data], { type })
    const link = document.createElement('a')

    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', name)
    link.click()

}

export const getFileFromUser = (accept = '') => new Promise((resolve) => {

    // Создаем input[type="file"]
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = accept
    input.style.display = 'none' // Скрываем элемент от пользователя

    // Добавляем обработчик события изменения
    input.addEventListener('change', () => {

        if (input.files && input.files.length > 0) {

            resolve(input.files[0]) // Резолвим первый файл

        } else {

            resolve(null)

        }
        document.body.removeChild(input) // Удаляем input после использования

    })

    // Добавляем input в документ, чтобы инициировать клик
    document.body.appendChild(input)

    // Инициируем клик
    input.click()

})

export const createFile = ({ content = '', type = 'text/plain', name = 'file.txt' }) => {
            
    const blob = new Blob([content], { type })
    return new File([blob], name, { type: blob.type })

}

export default {
    saveDataAsFile,
    getFileFromUser,
    createFile
}
