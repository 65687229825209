<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-text-field
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Год"
            type="number" 
            :color="$const.color.primary"
        ></v-text-field>

        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            label="Предмет"
        />

        <v-select
            v-model="$v.form.grade.$model"
            :disabled="!$v.form.subject.$model"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            label="Класс"
        />
        
        <v-select
            v-model="$v.form.category.$model"
            :error-messages="getErrors('form.category')"
            label="Категория"
            :items="categories"
            :color="$const.color.primary" 
        />

        <v-text-field
            v-model="$v.form.option.$model"
            :error-messages="getErrors('form.option')"
            label="Вариант"
            type="number" 
            :color="$const.color.primary"
        ></v-text-field>

        <v-text-field
            v-model="$v.form.task.$model"
            :error-messages="getErrors('form.task')"
            label="Задание"
            type="number" 
            :color="$const.color.primary"
        ></v-text-field>

        <v-select
            v-if="form.category === 'control'"
            v-model="$v.form.collection.$model"
            :error-messages="getErrors('form.collection')"
            required
            :items="collections"
            label="Коллекция"
            name="collection"
        ></v-select>
        
        <v-alert
          v-if="!_.isNil(serverErrors)"
          dense
          type="error"
        >
            {{ serverErrors }}
        </v-alert>

        <v-divider class="my-4" />
        <v-btn
            outlined
            :disabled="!canRequest"
            :loading="loading.export"
            @click="submitExport"
        >Экспорт</v-btn>
    </v-form>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import {
    // replaceURLsWithBase64,
    getURLsFromString,
    normalizeUrl,
    // getBase64ByUrl,
    getBlobByUrl
} from '@/helpers/URLHelper'
import { saveDataAsFile } from '@/helpers/File'
import { extractFiles, modifyZip } from '@/helpers/ZipHelper'

export default {
    name: 'TaskScoresReportForm',
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            loading: {
                initial: false,
                export: false
            },
            categories: [{value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'}],
            form: {
                year: (new Date()).getFullYear(),
                subject: '',
                grade: 0,
                option: 1,
                task: 1,
                category: 'test',
                collection: 1
            }
        };
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                option: { required },
                task: { required },
                category: { required },
                collection: { required }
            }
        }
    },
    watch: {
        'form.category'() {
            if (this.form.category === 'test')
                this.form.collection = 1
        }
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.form.subject) { return [] }
            return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
        },
        canRequest () {
            return  this.form.subject &&
                    this.form.grade && parseInt(this.form.grade) > 0 &&
                    this.form.year && parseInt(this.form.year) > 0 &&
                    this.form.task && parseInt(this.form.task) > 0 &&
                    this.form.option && parseInt(this.form.option) > 0 &&
                    this.form.collection && parseInt(this.form.collection) > 0
        },
        collections () {
            return (new Array(10)).fill(null).map((item, index) => index + 1)
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
    },
    methods: {
        async submitExport () {
            
            if (this.loading.export) { return false; }

            this.serverErrors = null

            if (!this.validate()) {
                console.error('Validation failed');
                return false;
            }
            
            try {
                this.loading.export = true
                
                let model = await this.findTaskData(this.form)

                if (!model) throw new Error('Задание по указанному фильтру не найдено')

                if (typeof model === 'object')
                    model = JSON.stringify(model)

                const [urls, fileNames, blobFromUrls] = await this.extractBlobFromDomString(model)

                for (let index = 0; index < fileNames.length; index++)
                    model = model.replace(urls[index], `./assets/${fileNames[index]}`)

                model = JSON.stringify({ ...this.form, ...JSON.parse(model) })

                const zipFile = await this.buildAppArchive(model, fileNames.map((name, index) => ({ path: `assets/${name}`, blob: blobFromUrls[index] })))

                saveDataAsFile({
                    data: zipFile,
                    type: zipFile.type,
                    name: `okovpr_task_build.zip`
                })
            } catch (e) {
                console.error(e)
                this.serverErrors = e?.message || 'Ошибка формирования файла'
            }
            finally {
                this.loading.export = false
                this.$emit('success', 1);
            }
        },
        async extractBlobFromDomString (str) {
            const urls = getURLsFromString(str)
            const fileNames = urls.map((url) => {
                const strChunks = url.split('/')
                return strChunks[strChunks.length - 1]
            })
            const normalizedUrls = urls.map((url) => normalizeUrl(url))
            const blobFromUrls = []

            for (const url of normalizedUrls)
                blobFromUrls.push(await getBlobByUrl(url))

            return [urls, fileNames, blobFromUrls]
        },
        async findTaskData (filter) {

            const response = await this.$store.dispatch('task/list', { filter, fields: 'data' })

            return response?.data?.items?.[0]?.data || null
        },
        /**
         * @param {String} taskContent JSON with task data
         * @param {Array<Object>} taskAssets Array of objects with info about assets. Structure of each object is as follows: { path: String, blob: Blob }
         * @returns {Blob} Built app archive
         */
        async buildAppArchive (taskContent, taskAssets) {

            const appPath = '/demo-app.zip'
            const { data: extractedFiles} = (await extractFiles(appPath, [{ path: 'index.html', mimeType: 'text/html' }]))
            
            if (!extractedFiles[0]) throw new Error('Ошибка сборки файлов приложения')

            const replaceWhere = '<script id=data-json type=application/json></' + 'script>'
            const replaceWhat = `<script id=data-json type=application/json>${taskContent}</` + 'script>'
            let indexFileContent = await extractedFiles[0].text()
            indexFileContent = indexFileContent.replace(replaceWhere, replaceWhat)

            const zipFile = await getBlobByUrl(appPath)
    
            const modifiedZip = await modifyZip(zipFile, [
                { path: 'index.html', content: new Blob([indexFileContent], { type: 'text/html' })},
                ...taskAssets.map((file) => ({ path: file.path, content: file.blob }))
            ])

            return modifiedZip
        }
    }
}
</script>